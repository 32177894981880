<template>
	<div class="page en-font" style="background-color: #fff;">
		<div class="container">

			<div class="content">
        
        <!-- 实名认证 -->
        <div v-if="nodes.idCard || isReRealAuth" class="section">
          <div class="title en-font">1. Verification</div>
          <div class="input-box input-bg">
            <div class="label">ID Type</div>
            <van-dropdown-menu active-color="#000">
              <van-dropdown-item :title="idCardObj.cartName || 'Please select your ID type'"  ref="dropdown-menu-item">
                <template v-for="(item, i) in certtypes">
                  <van-cell :key="i" center :title="item.text" :class="[info.id_card_type == (i + 1) ? 'dropdown-menu-active' : '']" @click="getCertType(item.value)">
                    <template #icon>
                      <van-icon name="success" class="dropdown-menu-icon" />
                    </template>
                  </van-cell>
                </template>
              </van-dropdown-item>
            </van-dropdown-menu>
          </div>
          
          <div style="width: 100%;">
            <div class="input-box input-bg">
              <div class="label">Name</div>
              <van-field ref="idCardName" v-model="info.id_card_name" :placeholder="info.id_card_type == 1 ? '请输入中文姓名' : 'Your Name'" class="input font14" />
            </div>
            <div class="input-box input-bg">
              <div class="label">ID Number</div>
              
              <van-field v-if="info.id_card_type != 1" v-model="info.id_card_code" placeholder="Your ID Number" class="input font14" style="margin-bottom: 0;" />
              
              <van-field v-else ref="idCardCode" readonly clickable :value="info.id_card_code" placeholder="Your ID Number" @touchstart.native.stop="isShowKeyboard = true" class="input font14 radius" style="margin-bottom: 0;" />
              <!-- 身份证号小键盘 -->
              <van-number-keyboard v-model="info.id_card_code" extra-key="X" close-button-text="Close" :show="isShowKeyboard" :maxlength="18" @blur="isShowKeyboard = false" />
            </div>
            
            <div v-if="info.id_card_type && info.id_card_type != 1" class="bg-white radius" @click="shootCard">
              <div class="label" style="padding-left: 5px; margin-bottom: 10px;">Upload ID image</div>
              <div class="flex justify-between align-center">
                <div class="camera input-bg relative flex justify-center align-center">
                  <img v-if="info.id_card_image_path" :src="info.id_card_image_path" class="fill img" />
                  <div v-else class="camera-icon">
                    <van-icon name="photograph" class="" />
                  </div>
                  <div v-if="isShowReRealTips" class="clear-icon absolute">×</div>
                </div>
                <div v-if="info.id_card_image_path && !idCardObj.isReUpload" class="checkfill-icon">
                  <van-icon name="checked" />
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
        
        <template v-if="!isReRealAuth">
          
          <!-- 基本信息 -->
          <div v-if="nodes.card || nodes.userInfo" class="section">
            
            <div class="title">
              <span v-if="nodes.idCard">2</span>
              <span v-else>1</span>
              <span>. Personal Information</span>
            </div>
            <div v-if="nodes.card && nodes.userInfo" class="tab-bar flex align-center text-center margin-bottom" :style="{'background-color': style.btnColor, 'border-color': style.btnColor}">
              <div class="tab flex-sub" :style="{'background-color': info.card_or_userinfo == 1 ? style.btnColor : '#ffffff', color: info.card_or_userinfo == 1 ? '#fff' : '#333',}" @click="onTabChange(1)">Autofill</div>
              <div class="tab flex-sub" :style="{'background-color': info.card_or_userinfo == 2 ? style.btnColor : '#ffffff', color: info.card_or_userinfo == 2 ? '#fff' : '#333',}" @click="onTabChange(2)">Enter Manually</div>
            </div>
            
            <!-- 名片上传 -->
            <div :class="info.card_or_userinfo == 1 ? 'block' : 'hide'">
              <div class="camera-box">
                <div class="label1">Business Card (front)</div>
                <div class="flex justify-between align-center">
                  <div class="camera input-bg relative flex justify-center align-center" @click="onPhote('aCard')">
                    <img v-if="cardObj.aCardPath" :src="cardObj.aCardPath" class="fill radius"></img>
                    <van-icon v-else name="photograph" class="camera-icon" />
                    <div v-if="cardObj.aCardPath && !cardObj.frontchek" class="clear-icon absolute">×</div>
                  </div>
                  <div v-if="cardObj.frontchek" class="checkfill-icon-box">
                    <van-icon name="checked" class="checkfill-icon" :style="{color: style.btnColor}" />
                  </div>
                </div>
              </div>
              <div class="camera-box">
                <div class="label1">Business Card (back)</div>
                <div class="flex justify-between align-center">
                  <div class="camera input-bg relative flex justify-center align-center" @click="onPhote('bCard')">
                    <img v-if="cardObj.bCardPath" :src="cardObj.bCardPath" class="fill radius"></img>
                    <van-icon v-else name="photograph" class="camera-icon" />
                    <div v-if="cardObj.bCardPath && !cardObj.backchek" class="clear-icon absolute">×</div>
                  </div>
                  <div v-if="cardObj.backchek" class="checkfill-icon-box">
                    <van-icon name="checked" class="checkfill-icon" :style="{color: style.btnColor}" />
                  </div>
                </div>
              </div>
            </div>
            
            <!-- 手动填写 -->
            <div :class="info.card_or_userinfo == 2 ? 'block' : 'hide'" class="form">
              
              <template v-for="(item, idx) in informationOptions">
                <div v-if="item.isShow" :key="idx">
                  
                  <div v-if="item.id === 'area'" class="input-box input-bg">
                    <span class="label">Country</span>
                    <span v-if="item.isRequired" class="text-red">&nbsp;*</span>
                    <van-field readonly clickable :value="info.provinceName" left-icon="play"  @click="showPicker">
                      <template #input>
                        <input v-model.trim="info.provinceName" type="text" :ref="item.id" class="input" placeholder="Please Input Your Country">
                      </template>
                    </van-field>
                    
                    <van-popup v-model="isShowPicker" round position="bottom">
                      <van-picker show-toolbar :columns="columns" :default-index="defaultIdx" confirm-button-text="Confirm" cancel-button-text="Cancel" @cancel="isShowPicker = false" @confirm="onConfirm" >
                        <template #columns-top>
                          <van-search v-model="keyword" shape="round" background="#ffffff" placeholder="Search" @input="searchLocation" />
                        </template>
                      </van-picker>
                    </van-popup>
                  </div>
                  <div v-if="item.id === 'area'" class="input-box input-bg">
                    <span class="label">City</span>
                    <span v-if="item.isRequired" class="text-red">&nbsp;*</span>
                    <van-field>
                      <template #input>
                        <input v-model.trim="info.cityName" type="text" :ref="item.id" class="input" placeholder="Please Input Your City">
                      </template>
                    </van-field>
                  </div>
                  
                  <div v-else-if="item.id === 'position'" class="input-box input-bg">
                    <span class="label">{{item.name}}</span>
                    <span v-if="item.isRequired" class="text-red">&nbsp;*</span>
                    <van-field>
                      <template #input>
                        <input v-model.trim="info[`${item.id}`]" type="text" :ref="item.id" class="input" placeholder="Please Input Your Job title or position">
                      </template>
                    </van-field>
                  </div>
                  
                  <div v-else class="input-box input-bg">
                    <span class="label">{{item.name}}</span>
                    <span v-if="item.isRequired" class="text-red">&nbsp;*</span>
                    <van-field>
                      <template #input>
                        <input v-model.trim="info[`${item.id}`]" type="text" :ref="item.id" class="input font14" :placeholder="'Please Input Your ' + item.name">
                      </template>
                    </van-field>
                  </div>
                  
                </div>
              </template>
              
            </div>
          </div>
          
          <!-- 所属行业 -->
          <div v-if="nodes.trade" class="section">
            <div class="title en-font">
              <span v-if="nodes.idCard && (nodes.card || nodes.userInfo)">3</span>
              <span v-else-if="nodes.idCard && !nodes.card && !nodes.userInfo">2</span>
              <span v-else-if="!nodes.idCard && (nodes.card || nodes.userInfo)">2</span>
              <span v-else>3</span>
              <span>. Select the Category that Best Describes Your Profession</span>
            </div>
            
            <div class="industry-container">
  
              <div v-for="(item, index) in tradeList" :key="index">
                <!--有children属性时 -->
                <template v-if="item.children && item.children.length > 0">
                  <div class="text-black industry-item flex align-center en" @click="toggleTrade(item.id)" >
                    <span class="block">{{item.en_name}}</span>
                    <van-icon name="play" :class="[item.isExpand ? 'rotate-90' : '', 'text-black','icon']" />
                  </div>
                  <div :class="['children-container', item.isExpand ? 'children-container-show' : '']">
                    <div :class="['children-list', item.isExpand ? 'children-list-show' : '']">
                      <div v-for="(sub, idx) in item.children" :key="idx">
                        
                        <!-- <div class="sub-item flex align-center en" :style="{'background-color': sub.id == info.trade ? style.btnColor : '', color: sub.id == info.trade ? '#fff' : '#333',}" @click="bindhyxz(sub.id)">
                          <van-icon v-if="sub.id == info.trade" name="success" class="checked" />
                          <span class="block">{{sub.en_name}}</span>
                        </div> -->
                        
                        <!--有children属性时(三级) -->
                        <template v-if="sub.children && sub.children.length > 0">
                          <div class="text-black sub-item flex align-center en" @click="toggleSubTrade(item.id, sub.id)" >
                            <span class="block">{{sub.en_name}}</span>
                            <van-icon name="play" :class="[sub.isExpand ? 'rotate-90' : '', 'text-black','icon']" />
                          </div>
                          <div :class="['children-container', sub.isExpand ? 'children-container-show' : '']">
                            <div :class="['children-list', sub.isExpand ? 'children-list-show' : '']">
                              <template v-for="(lastSub, i) in sub.children">
                                <div :key="i" class="last-sub-item flex align-center en" :style="{'background-color': lastSub.id == info.trade ? style.btnColor : '', color: lastSub.id == info.trade ? '#fff' : '#333',}" @click="bindhyxz(lastSub.id)">
                                  <van-icon v-if="lastSub.id == info.trade" name="success" class="checked" />
                                  <span class="block">{{lastSub.en_name}}</span>
                                </div>
                              </template>
                            </div>
                          </div>
                        </template>
                        <!--无children属性时 -->
                        <div v-else class="sub-item flex align-center en" :style="{'background-color': sub.id == info.trade ? style.btnColor : '', color: sub.id == info.trade ? '#fff' : '#333',}" @click="bindhyxz(sub.id)">
                          <van-icon v-if="sub.id == info.trade" name="success" class="checked" />
                          <span class="block">{{sub.en_name}}</span>
                        </div>
                        
                        
                      </div>
                    </div>
                  </div>
                </template>
                <!--无children属性时 -->
                <div v-else class="industry-item flex align-center text-black en" :style="{'background-color': item.id == info.trade ? style.btnColor : '', color: item.id == info.trade ? '#fff' : '#333'}" @click="bindhyxz(item.id)">
                  <van-icon v-if="item.id == info.trade" name="success" class="checked" />
                  <span class="block">{{item.en_name}}</span>
                  <input v-if="item.id == 99" v-model.trim="info.trade_other" :readonly="!allowInputOther" type="text" maxlength="100" class="other-input font14">
                </div>
              </div>
              
            </div>
            
          </div>
          
        </template>
        <button class="next-btn1 radius" :style="{'background-color': style.btnColor || btnColor}" @click="nextStep">Submit</button>
				<van-button type="primary" block :color="style.btnColor || btnColor" class="next-btn radius" @click="nextStep">Submit</van-button>
			
      </div>
		</div>
		
		<input v-show="false" type="file" id="fileElem" accept="image/*" ref="imgFile" @change="chooseImg">
    
    <!-- 名片拍摄示范 -->
    <van-overlay :show="isShowModal" style="display: flex;justify-content: space-between;align-items: center;">
    	<div class="tips-content bg-white" @click.stop>
        <div class="img-box">
          <img mode="widthFix" :src="style.shootTipsImg" class="img" />
        </div>
        <div class="flex flex-direction margin-bottom">
          <span class="title">Photo taking guidelines</span>
          <div class="desc" style="text-align: left;">Use the horizontal orientation of your mobile device. Make sure the photo is clearly visible and positioned within the defined border. Business Cards ONLY. DO NOT upload photos of other forms of identification</div>

        </div>
        <div class="btn" :style="{'background-color': style.btnColor, color: '#fff',}" @click="shootStart">Take photo</div>
        <div class="btn close text-black" @click="hideModal">{{lang === 'en' ? 'Cancel' : '关闭' }}</div>
    	</div>
    </van-overlay>
		
	</div>
</template>

<script>

	import commonMixin from '@/view/process/mixin/common.js';
	import realnameAuthMixin from '@/view/process/mixin/realnameAuth.js';

	export default {
		mixins: [commonMixin, realnameAuthMixin],
		data() {
			return {
				
			}
		},
		created() {
			this.setLanguge('en'); // 设置语言环境
		},

		methods: {
			
		},
	}
</script>

<style lang="less" scoped>
.align-center {
  align-items: center;
}
  
// PC
@media screen and (min-width: 751px) {
  .font14 {
    font-size: 28Px;
  }
  
  .input-bg {
    background-color: rgb(245, 245, 245);
  }
  
  .input-box {
    border-radius: 5Px;
    height: 120Px;
    padding: 12Px 30Px;
    margin-bottom: 30Px;
  }
  
  .container {
    
    .content {
      width: 680Px;
      margin: 0 auto;
      padding: 40Px 0 160Px;
      color: #000;
      font-size: 28Px;
      
      .section {
        color: #000000;
        margin-bottom: 120Px;
      }
      
      .title {
        font-size: 40Px;
        font-weight: bold;
        margin-bottom: 48Px;
        padding-left: 10Px;
      }
      
      .label {
        color: #888;
        font-size: 28Px;
        line-height: 40Px;
        letter-spacing: 1Px;
      }
      .label1 {
        color: #000;
        font-size: 28Px;
        line-height: 40Px;
        letter-spacing: 1Px;
        margin-left: 10Px;
        margin-bottom: 20Px;
      }
      .input {
        background-color: transparent;
        padding: 0;
        height: 50Px;
        font-size: 24Px;
        
        /deep/ .van-field__control {
          min-height: 50Px!important;
          height: 50Px;
          font-size: 24Px;
        }
      }
      
      .camera-box {
        margin-bottom: 40Px;
      }
      .camera {
        width: 128Px;
        height: 128Px;
        border-radius: 8Px;
        overflow: hidden;
      }
      
      .camera-icon {
        font-size: 56Px;
      }
      .clear-icon {
        top: 4Px;
        right: 4Px;
        color: #fff;
        font-size: 48Px;
        width: 48Px;
        height: 48Px;
        padding-bottom: 4Px;
        background-color: #e54d42;
        border-radius: 6Px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .checkfill-icon-box {  
        width: 40Px;
        height: 40Px;
        border-radius: 80Px;
        margin-right: 20Px;
      }
      
      .checkfill-icon {
        display: block;
        margin-top: -6Px;
        margin-left: -6Px;
        font-size: 56Px;
        line-height: 56Px;
      }
      
      /deep/ .van-dropdown-menu {
  
        .van-dropdown-menu__bar {
          box-shadow: none;
          height: 68Px;
          background-color: transparent!important;
        }
        
        .van-dropdown-menu__title {
          width: 520Px;
          font-size: 28Px;
          padding: 0 20Px 0 40Px;
        }
        .van-dropdown-menu__title::after {
          top: 60%;
          left: 0;
          right: 100%;
          border: 8Px solid;
          border-color: transparent transparent #000000 #000000;
        }
        .van-dropdown-menu__item {
          justify-content: flex-start;
        }

        .van-dropdown-item {
          overflow-x: auto;
          overflow-y: hidden;
        }
        .van-overlay {
          width: 750Px;
          background-color: rgba(0,0,0,0);
        }
        .van-popup--top {
          width: 680Px;
          top: 20Px;
          left: 50%;
          transform: translateX(-50%);
        }
        .van-dropdown-item__content {
          box-shadow: 0 4px 15px rgba(0, 0, 0, .2);
          border-radius: 8px;
        }
        .van-cell {
          width: auto;
          margin: 15Px;
          border-radius: 8Px;
          padding: 5Px;
          font-size: 28Px;
        }
        .van-dropdown-item__option--active {
          color: #fff!important;
          background-color: #000000!important;
        }
        .van-dropdown-item__option--active::before {
          // content: '\e728';
          content: '✔';
          width: 40Px;
          height: 40Px;
          color: #fff;
          font-size: 28Px;
        }
        .van-dropdown-item__option .van-cell__value {
          display: none;
        }
        
        
        .dropdown-menu-icon {
          font-size: 40Px;
          margin-right: 12Px;
          color: #fff;
        }
        
        .dropdown-menu-active {
          color: #fff;
          background-color: #000000;
          font-size: 28Px;
        }
      }
  
      .tab-bar {
        border: 4Px solid ;
        height: 80Px;
        box-sizing: border-box;
        border-radius: 8Px;
        margin-bottom: 30Px;
        
        .tab {
          height: 100%;
          font-size: 28Px;
          letter-spacing: 1Px;
          border-radius: 6Px;
  
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      
      .form {
      
      	.input {
      		width: 100%;
      		border: none;
          
      		&::placeholder {
      			color: #bbb;
      		}
      	}
      
      	/deep/ .van-cell {
          
          width: auto;
          border-radius: 8Px;
          padding:  0;
          background-color: transparent;
          
          .van-field__left-icon {
            width: 30Px;
            height: 30Px;
            margin-right: 10Px;
          }
      		.van-icon-play {
            display: block;
      			transform: rotate(90deg);
            margin-top: 55%;
            font-size: 24Px;
            width: 30Px;
            height: 30Px;
            display: flex;
            align-items: center;
      		}
          
          .van-field__control {
            min-height: 60Px;
            height: 60Px;
            font-size: 24Px;
          }
      	}
      }
      
      /deep/ .van-picker {
      	padding-top: 20Px;
        
        .van-picker__toolbar {
          height: 80Px;
          
          .van-picker__cancel, .van-picker__confirm {
              font-size: 28Px;
          }
        }
        
      	.van-cell {
      		border-radius: 10Px;
          padding-left: 0;
          font-size: 24Px;
          line-height: 60Px;
        }
        .van-search {
          margin: 0 20Px;
          padding: 10Px 0;
          
          .van-field__control {
            text-align: center!important;
            
            &::placeholder {
              text-align: center!important;
            }
          }
        }
        
        .van-picker-column {
          font-size: 24Px;
        }
      	
      }
      
      .industry-container {
        background: #FFFFFF;
        width: 680Px;
        padding: 10Px 28Px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 16Px;
        margin-bottom: 100Px;
        box-shadow: 0 10Px 30Px rgba(5, 16, 55, 0.15);
        
        .industry-item,
        .sub-item,
        .last-sub-item {
          position: relative;
          font-size: 32Px;
          min-height: 80Px;
          line-height: 48Px;
          font-weight: 400;
          border-radius: 12Px;
          cursor: pointer;
        }
        .industry-item {
          width: 630Px;
          margin: 12Px 0;
          padding-left: 72Px;
        }
        .industry-item.en,
        .sub-item.en,
        .last-sub-item {
          font-size: 30Px;
        }
  
        .sub-item {
          text-align: left;
          padding-left: 40px;
        }
        .last-sub-item {
          text-align: left;
          padding-left: 55px;
        }
  
        // .sub-item:last-child {
        //   margin-bottom: 20Px;
        // }
  
        .industry-item .icon,
        .sub-item .icon {
          position: absolute;
          top: 0;
          right: 0;
          height: 80Px;
          line-height: 80Px;
          font-size: 32Px;
        }
  
        .industry-item .checked,
        .sub-item .checked,
        .last-sub-item .checked{
          position: absolute;
          left: 18Px;
          top: 50%;
          transform: translateY(-50%);
          height: 80Px;
          line-height: 80Px;
          font-size: 36Px;
        }
  
        .children-container {
          width: 100%;
          height: 0;
          overflow: hidden;
        }
  
        .children-container-show {
          height: auto;
        }
        
        .rotate-90 {
          transform: rotate(90deg);
        }
        
        .other-input {
          width: 400Px;
          height: 54Px;
          margin: 0 0 0 20Px;
          padding: 0 10Px;
          border-radius: 4Px;
          background-color: #fff;
          color: #333;
          border: none;
          font-size: 24Px;
        }
        
      }
  
    }
    
  }
  
  .tips-content {
  	width: 640Px;
    padding: 32Px;
    border-radius: 16Px;
  	margin: 10vh auto 0;
    
    .img-box {
      width: 80%;
      border-radius: 16Px;
      margin: 24Px auto;
    }
    .img-box .img {
      width: 100%;
      height: auto;
    }
    .title {
      font-weight: 600;
      font-size: 44Px;
      text-align: center;
      line-height: 64Px;
      color: #333;
      margin-bottom: 16Px;
    }
    .desc {
      font-weight: 400;
      font-size: 26Px;
      text-align: center;
      line-height: 46Px;
      color: rgb(158, 158, 158);
      margin-bottom: 40Px;
    }
    
    .btn {
      height: 100Px;
      line-height: 100Px;
      font-weight: 500;
      font-size: 32Px;
      letter-spacing: 1px;
      text-align: center;
      border-radius: 16Px;
      margin-bottom: 20Px;
    }
    .close {
      background-color: rgb(245, 245, 245);
    }
  
  }
  
  .next-btn {
    display: none;
  }
  .next-btn1 {
    display: block;
    width: 520Px;
    height: 80Px;
    margin: 6vh auto 3vh;
    letter-spacing: 1px;
    font-size: 24Px;
    border: none;
    cursor: pointer;
    color: #FFFFFF;
  }
  
  .checkfill-icon {
    color: #000000;
    font-size: 56Px;
  }
  
  
}

// 移动
@media screen and (max-width: 750px) {
  .input-bg {
    background-color: rgb(245, 245, 245);
  }
  
  .input-box {
    border-radius: 5px;
    height: 60px;
    padding: 6px 15px;
    margin-bottom: 15px;
  }
  
  .container {
    
    .content {
      width: 340px;
      margin: 0 auto;
      padding: 20px 0 80px;
      color: #000;
      font-size: 14px;
      
      .section {
        color: #000000;
        margin-bottom: 60px;
      }
      
      .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 12px;
        padding-left: 5px;
      }
      
      .label {
        color: #888;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
      }
      .label1 {
        color: #000;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        margin-left: 5px;
        margin-bottom: 10px;
      }
      .input {
        background-color: transparent;
        padding: 0;
      }
      
      .camera-box {
        margin-bottom: 20px;
      }
      .camera {
        width: 64px;
        height: 64px;
        border-radius: 8px;
        overflow: hidden;
      }
      
      .camera-icon {
        font-size: 28px;
      }
      .clear-icon {
        top: 2px;
        right: 2px;
        color: #fff;
        font-size: 24px;
        width: 24px;
        height: 24px;
        padding-bottom: 2px;
        background-color: #e54d42;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .checkfill-icon-box {  
        width: 20px;
        height: 20px;
        border-radius: 50px;
        margin-right: 10px;
      }
      
      .checkfill-icon {
        display: block;
        margin-top: -3px;
        margin-left: -3px;
        font-size: 28px;
        line-height: 28px;
      }
      
      /deep/ .van-dropdown-menu {
  
        .van-dropdown-menu__bar {
          box-shadow: none;
          height: 34px;
          background-color: transparent!important;
        }
        
        .van-dropdown-menu__title {
          width: 260px;
          font-size: 14px;
          padding: 0 10px 0 20px;
        }
        .van-dropdown-menu__title::after {
          top: 40%;
          left: 0;
          right: 100%;
          border: 4px solid;
          border-color: transparent transparent #000000 #000000;
        }
        .van-dropdown-menu__item {
          justify-content: flex-start;
        }
  
        .van-dropdown-item {
          overflow-x: auto;
          overflow-y: hidden;
        }
        .van-overlay {
          width: 375px;
          background-color: rgba(0,0,0,0);
        }
        .van-popup--top {
          width: 340px;
          left: 17px;
          top: 10px;
        }
        .van-dropdown-item__content {
          box-shadow: 0 4px 15px rgba(0, 0, 0, .2);
          border-radius: 8px;
        }
        .van-cell {
          width: auto;
          margin: 10px;
          border-radius: 4px;
          padding: 6px ;
        }
        .van-dropdown-item__option--active {
          color: #fff!important;
          background-color: #000000!important;
        }
        .van-dropdown-item__option--active::before {
          // content: '\e728';
          content: '✔';
          width: 20px;
          height: 20px;
          color: #fff;
          font-size: 14px;
        }
        .van-dropdown-item__option .van-cell__value {
          display: none;
        }
        
        .dropdown-menu-icon {
          font-size: 20px;
          margin-right: 6px;
          color: #fff;
        }
        
        .dropdown-menu-active {
          color: #fff;
          background-color: #000000;
        }
      }
  
      .tab-bar {
        border: 2px solid ;
        height: 40px;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 15px;
        
        .tab {
          height: 100%;
          font-size: 14px;
          letter-spacing: 1px;
          border-radius: 3px;
  
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      
      .form {
      
      	.input {
      		width: 100%;
      		border: none;
      
      		&::placeholder {
      			color: #bbb;
      		}
      	}
      
      	/deep/ .van-cell {
          
          width: auto;
          border-radius: 4px;
          padding: 6px 0;
          background-color: transparent;
          
      		.van-icon-play {
      			transform: rotate(90deg);
            margin: -2px 10px 0 0;
      		}
      	}
      }
      
      /deep/ .van-picker {
      	padding-top: 10px;
        
      	.van-cell {
      		border-radius: 5px;
          padding-left: 0;
        }
        .van-search {
          margin: 0 10px;
          padding-left: 0;
          padding-right: 0;
          
          .van-field__control {
            text-align: center!important;
            
            &::placeholder {
              text-align: center!important;
            }
          }
        }
      	
      }
      
      .industry-container {
        background: #FFFFFF;
        width: 340px;
        padding: 5px 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        margin-bottom: 50px;
        box-shadow: 0 5px 15px rgba(5, 16, 55, 0.15);
        
        .industry-item,
        .sub-item,
        .last-sub-item {
          position: relative;
          font-size: 16px;
          min-height: 40px;
          line-height: 24px;
          font-weight: 400;
          border-radius: 6px;
        }
        .industry-item {
          width: 315px;
          margin: 6px 0;
          padding-left: 36px;
        }
        .industry-item.en,
        .sub-item.en,
        .last-sub-item.en {
          font-size: 15px;
        }
  
        .sub-item {
          text-align: left;
          padding-left: 55px;
        }
        .last-sub-item {
          text-align: left;
          padding-left: 75px;
        }
  
        // .sub-item:last-child,
        // .last-sub-item:last-child {
        //   margin-bottom: 10px;
        // }
  
        .industry-item .icon,
        .sub-item .icon {
          position: absolute;
          top: 0;
          right: 0;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
        }
  
        .industry-item .checked,
        .sub-item .checked,
        .last-sub-item .checked {
          position: absolute;
          left: 9px;
          top: 50%;
          transform: translateY(-50%);
          height: 40px;
          line-height: 40px;
          font-size: 18px;
        }
  
        .children-container {
          width: 100%;
          height: 0;
          overflow: hidden;
        }
  
        .children-container-show {
          height: auto;
        }
        
        .rotate-90 {
          transform: rotate(90deg);
        }
        
        .other-input {
          width: 190px;
          height: 30px;
          margin: 0 0 0 15px;
          padding: 0 9px;
          border-radius: 4px;
          background-color: #fff;
          color: #333;
          border: none;
          font-size: 14px;
        }
        
      }
  
    }
    
  }
  
  .tips-content {
  	width: 320px;
    padding: 16px;
    border-radius: 8px;
  	margin: 10vh auto 0;
    
    .img-box {
      width: 80%;
      border-radius: 8px;
      margin: 12px auto;
    }
    .img-box .img {
      width: 100%;
      height: auto;
    }
    .title {
      font-weight: 600;
      font-size: 22px;
      text-align: center;
      line-height: 32px;
      color: #333;
      margin-bottom: 8px;
    }
    .desc {
      font-weight: 400;
      font-size: 13px;
      text-align: center;
      line-height: 23px;
      color: rgb(158, 158, 158);
      margin-bottom: 20px;
    }
    
    .btn {
      height: 50px;
      line-height: 50px;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
      text-align: center;
      border-radius: 8px;
      margin-bottom: 10px;
    }
    .close {
      background-color: rgb(245, 245, 245);
    }
  
  }
  .next-btn1 {
    display: none;
  }
  .next-btn {
  	width: 260px;
  	margin: 6vh auto 3vh;
  	letter-spacing: 1px;
  	font-size: 18px;
  }
  
  .checkfill-icon {
    color: #000000;
    font-size: 28px;
  }
  
}  

</style>
